export enum AcuityButtonType {
  JOIN_TIA = 'JOIN_TIA',
  CHECK_NETWORK = 'CHECK_NETWORK',
  BOOK_APPOINTMENT = 'BOOK_APPOINTMENT',
}

interface AcuityPixelConfig {
  pixelKey: string
  pg: number
}

const ACUITY_BUTTON_CONFIGS: Readonly<Record<AcuityButtonType, Readonly<AcuityPixelConfig>>> = {
  [AcuityButtonType.JOIN_TIA]: {
    pixelKey: '6241371298734219010',
    pg: 39732,
  },

  [AcuityButtonType.CHECK_NETWORK]: {
    pixelKey: '6241371298734219010',
    pg: 39734,
  },

  [AcuityButtonType.BOOK_APPOINTMENT]: {
    pixelKey: '6241371298734219010',
    pg: 39736,
  },
}

const NoOp = () => {}

export const buildTrackingEvent = (linkCta: string | undefined): {
  dispatch: () => void,
  success: boolean,
  type: AcuityButtonType | null
} => {
  let type: AcuityButtonType | null = null
  if (linkCta?.includes('join')) {
    type = AcuityButtonType.JOIN_TIA
  } else if (linkCta?.includes('network')) {
    type = AcuityButtonType.CHECK_NETWORK
  } else if (linkCta?.includes('appointment')) {
    type = AcuityButtonType.BOOK_APPOINTMENT
  }

  const success = type !== null
  const dispatch = type !== null ? () => trackButtonClick(type) : NoOp

  return {
    success,
    type,
    dispatch
  }
}

export const trackButtonClick = (buttonType: AcuityButtonType): void => {
  if ('function' === typeof window.aap) {
    const config = ACUITY_BUTTON_CONFIGS[buttonType]

    try {
      window.aap(config)
    } catch (error) {
      console.error('Failed to fire Acuity pixel:', error)
    }
  } else {
    console.warn('Acuity pixel not initialized')
  }
}

export default { trackButtonClick, buildTrackingEvent}