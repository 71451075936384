import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { PortableText } from '@portabletext/react'
import { GlobalBannerProps } from 'src/interfaces/GlobalBanner'
import { Serializer } from 'src/utils/serializer'

interface BannerProps {
  globalBannerProps: GlobalBannerProps | undefined
  classes: any
}

const GlobalBanner = ({ globalBannerProps, classes }: BannerProps) => {
  const [isClient, setIsclient] = useState(false)

  useEffect(() => {
    setIsclient(true)
  }, [])

  if (
    !isClient ||
    !globalBannerProps ||
    Object.keys(globalBannerProps).length === 0
  ) {
    return null
  }

  return (
    <div
      className={cx(classes.banner, {
        [classes.bgPrimary1]:
          globalBannerProps?.backgroundColor === 'primary-1',
        [classes.bgPrimary2]:
          globalBannerProps?.backgroundColor === 'primary-2',
        [classes.bgPrimary3]:
          globalBannerProps?.backgroundColor === 'primary-3',
        [classes.bgSecondary1]:
          globalBannerProps?.backgroundColor === 'secondary-1',
        [classes.bgSecondary2]:
          globalBannerProps?.backgroundColor === 'secondary-2',
        [classes.bgSecondary3]:
          globalBannerProps?.backgroundColor === 'secondary-3',
        [classes.bgSecondary4]:
          globalBannerProps?.backgroundColor === 'secondary-4',
        [classes.bgSecondary5]:
          globalBannerProps?.backgroundColor === 'secondary-5',
        [classes.bgSecondary6]:
          globalBannerProps?.backgroundColor === 'secondary-6',
        [classes.bgTertiary1]:
          globalBannerProps?.backgroundColor === 'tertiary-1',
        [classes.bgTertiary2]:
          globalBannerProps?.backgroundColor === 'tertiary-2',
        [classes.bgTertiary3]:
          globalBannerProps?.backgroundColor === 'tertiary-3',
        [classes.bgTertiary4]:
          globalBannerProps?.backgroundColor === 'tertiary-4',
      })}
    >
      <div className={classes.bannerContent}>
        {/* @ts-expect-error */}
        <PortableText value={globalBannerProps?.text} components={Serializer} />
      </div>
    </div>
  )
}

export default GlobalBanner
