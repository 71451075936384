import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { normalizePortableText } from 'src/utils/normalizePortableText'

function CustomBlockContent(props) {
  const normalizedBlocks = normalizePortableText(props.blocks)

  return <BlockContent {...props} blocks={normalizedBlocks} />
}

export default CustomBlockContent
