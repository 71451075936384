export function normalizePortableText(node: any): any {
  if (Array.isArray(node)) {
    return node.map(normalizePortableText)
  } else if (node && typeof node === 'object') {
    // If the node is a block
    if (node._type === 'block') {
      // Force markDefs to be an array
      if (!Array.isArray(node.markDefs)) {
        node.markDefs = []
      }

      // Force each child marks to be an array
      if (Array.isArray(node.children)) {
        node.children = node.children.map((child: any) => {
          if (!Array.isArray(child.marks)) {
            child.marks = []
          }

          return child
        })
      }
    }

    // Recursion to normalize each property
    const out: any = {}
    for (const key in node) {
      if (node.hasOwnProperty(key)) {
        out[key] = normalizePortableText(node[key])
      }
    }

    return out
  }

  return node
}
